import React, {useState} from "react";
import {Chip} from '@mui/material';
import resumeFile from '../documents/resume.pdf';
import ProjectDetailsModal from './ProjectDetailsModal';
import SkillsMatrix from './SkillsMatrix';

const Services = () => {
  const [showSkillsMatrix, setShowSkillsMatrix] = useState(false);

  // services details
  const services = [
    {
      name: "Frontend Development",
      desc: "I’m passionate about designing and building experiences. I’ve worked with all major front-end frameworks on a wide variety of projects. I’ve tackled some complex requirements including real-time websocket-powered trading tools, WebRTC communications, and interactive 3D WebGL apps.",
      icon: "fab fa-html5",
      skills: [
        {name: "Javascript", value: 0},
        {name: "HTML", value: 0},
        {name: "CSS", value: 0},
        {name: "SASS", value: 0},
        {name: "Typescript", value: 0},
        {name: "Vue", value: 0},
        {name: "Angular", value: 0},
        {name: "React", value: 0},
        {name: "NextJS", value: 0},
        {name: "Data Visualization", value: 0},
        {name: "3D WebGL", value: 0},
        {name: "RxJs", value: 0},
        {name: "Websockets", value: 0},
        {name: "SSE", value: 0},
      ]
    },
    {
      name: "Backend Development",
      desc: "I’m experienced in architecting, building, and maintaining backend systems. I handled every aspect of the design and implementation on many of the projects I've worked on. A few of my notable accomplishments include bi-directional integration with 15+ platforms, distributed computational processing, and event-based workflow automation.",
      icon: "fas fa-server",
      skills: [
        {name: "Java", value: 0},
        {name: "Python", value: 0},
        {name: "C#", value: 0},
        {name: "PostgreSQL", value: 0},
        {name: "MongoDB", value: 0},
        {name: "DynamoDB", value: 0},
        {name: "Firestore", value: 0},
        {name: "Kubernetes", value: 0},
        {name: "Kafka", value: 0},
      ]
    },
    {
      name: "Web & Graphic Design",
      desc: "I love to be creative. In fact, there was a span of time when I wanted to be an artist. I’ve designed websites, UI/UX for apps, infographics, business cards, and marketing materials. I’ve worked with the majority of major design platforms.",
      icon: "fas fa-palette",
      skills: [
        {name: "Figma", value: 0},
        {name: "InVision", value: 0},
        {name: "Balsamiq", value: 0},
        {name: "Justinmind", value: 0},
        {name: "Adobe Photoshop", value: 0},
        {name: "Adobe Indesign", value: 0},
        {name: "Adobe Illustrator", value: 0},
      ]
    },
    {
      name: "Custom Solutions",
      desc: "My passion for technology has no bounds. I’ve utilized my passion to automate business processes, customize support portals, build CMS widgets, implement/configure CRMs, centralize data, and much more.",
      icon: "fas fa-code",
      skills: [
        {name: "Technology Selection", value: 0},
        {name: "Problem Solving", value: 0},
        {name: "Requirements Gathering", value: 0},
        {name: "Solution Documentation", value: 0},
      ]
    },

  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What do I do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          My expertise
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-6 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13 wow fadeInUp">
                    {" "}
                    <span className="fa-stack stacked-service-icons">
                      <i className={`fas fa-square fa-stack-2x background`}></i>
                      <i className={`${service?.icon} fa-stack-1x fa-inverse foreground`}></i>
                    </span>
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                  <div className="chip-list mt-4">
                    {service.skills.length > 0 &&
                      service.skills.map((skill, index) => (
                        <Chip label={skill.name} className="mr-2" />
                      ))}

                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;

import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import { scrollDuration } from "../config/commonConfig";
import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Matt McGrath",
      position: "CEO, Optimize Wealth Management",
      desc: "“It was really an amazing experience working with Jordan! He was totally responsive and very user-friendly. Great communication and skills. He was always very open to revisions and offered lots of insight as well as his own experience when it came to making changes to a project. I would highly recommend Jordan to anyone who is looking for an exceptionally talented and very professional freelancer.”",
    },
    {
      name: "Jonathon",
      position: "CTO, Jonathon Kopnik, Eff Creative Group & TickerTocker",
      desc: "“Jordan is an incredible asset with deep knowledge of financial web engineering. Cannot recommend him enough.”",
    },
    {
      name: "Joe Kramer",
      position: "Partner, Clariata",
      desc: "“Jordan is a very skilled, motivated, and hard working developer. He's done a fantastic job converting our existing application to a React app. I highly recommend him for any front end development needs. Communication has been excellent, and we wouldn't hesitate to bring him back on for future work.”",
    },
    {
      name: "Terry Baldree",
      position: "VP Sales & Marketing, Spec4Direct",
      desc: "“Our entire experience with your company has been fantastic. The immense research you put forth into our idea and industry clearly shows in the product.... Can't wait to be able to see the impact on our sales process.”",
    },
    {
      name: "Norman Malo",
      position: "Former President, National Financial (Fidelity)",
      desc: "“It's amazing to see the capabilities and potential of your financial platforms, as our meetings with some of the top Broker Dealers have shown... Looking forward to what the future brings.”",
    },
    {
      name: "Chuck Synovec",
      position: "Executive Brand Manager Finance, McGraw-Hill Education",
      desc: " “Per the recommendations and agreement of the authors we are proud to be featuring StockLinkU in three of our top selling Investments textbooks for the publishing schedule beginning in December 2013.... It is clear that StockLinkU strives to maintain the best solution for the application of the concepts covered in our books and believe that this working relationship is beneficial for both parties. We look forward to working with the team at StockLinkU well into the future.”",
    },
    {
      name: "Don Cameron",
      position: "Financial Service Assurance Partner, PWC",
      desc: "“My son is a student at the University of South Carolina and attended a Finance class last semester. As part of his Finance class curriculum, the class participated in a stock market project using StockLinkU....I wanted to thank you for developing a product that was such a benefit to my son.”",
    },
    {
      name: "Steven Welch",
      position: "Professor of Accounting & Finance, St. John's Univ.",
      desc: "“Myy personal experience with StockLinkU was that it is a nearly ideal tool for use in my introductory investments class. It is significantly better than any other free online simulation game that I have found, and it has the option of doing even more.... My students found StockLinkU to be easy to navigate and comprehensive in the amount of data that was accessible to research any investment idea.”",
    },
    {
      name: "Bruce Cole",
      position: "Professor of Finance, USC",
      desc: "“Jordan is a knowledgeable and gifted individual. I have come to depend upon his insights both as a technical advisor and as a businessman in the application of his program.... I have received great reviews from the students regarding how their learning experience was enhance by using the StockLinkU program.”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    pauseOnHover: true
  };

  return (
    <section>
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        <Parallax
          bgImage="images/projects/screenshots/kontractor/app/dashboard.png"
          bgImageAlt="Intro"
          strength={200}
        >
          <div className="hero-content section">
            <div className="container">
              {/* Heading */}
              <p className=" text-center mb-2 wow fadeIn">
                <span className="bg-primary text-dark px-2">Testimonials</span>
              </p>
              <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
                What my clients and their customers say
              </h2>
              {/* Heading end*/}
              <div className="row">
                <div className="col-lg-9 mx-auto wow fadeInUp">
                  <Slider {...settings}>
                    {reviews.length > 0 &&
                      reviews.map((review, index) => (
                        <div className="item text-center px-5" key={index}>
                          {" "}
                          <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                          <p className="text-5 text-white">{review.desc}</p>
                          { review.src ?
                            <>
                              <img
                                className="img-fluid d-inline-block w-auto rounded-circle shadow"
                                src={review.src}
                                alt={review.name}
                              />{" "}
                            </>
                            : null }

                          <strong className="d-block text-3 fw-600 text-white">
                            {review.name}
                          </strong>{" "}
                          <span className="text-light">{review.position}</span>{" "}
                        </div>
                      ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>

    </section>
  );
};

export default Testimonials;

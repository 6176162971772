import React, {useEffect, useRef, useState} from "react";

import Slider from "react-slick";
import ModalVideo from "react-modal-video";
import {Button, Icon, IconButton, Paper} from '@mui/material';
import Accordion from 'react-bootstrap/Accordion';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import BusinessIcon from '@mui/icons-material/Business';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DateRangeIcon from '@mui/icons-material/DateRange';

const ProjectDetailsModal = ({projectDetails, setIsOpen}) => {
  const [activeSliderIndex, setActiveSliderIndex] = useState(0);
  const sliderRef = useRef();
  const [sliderRefState, setSliderRefState] = useState();
  const sliderDotsRef = useRef();

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const SlickArrowLeft = ({currentSlide, slideCount, ...props}) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({currentSlide, slideCount, ...props}) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    fade: true,
    arrows: true,
    prevArrow: <SlickArrowLeft/>,
    nextArrow: <SlickArrowRight/>,
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    pauseOnHover: true,
    pauseOnFocus: true,
    centerMode: true,
    beforeChange: (oldIndex, newIndex) => {
      setActiveSliderIndex(newIndex);
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const selectSlide = (index) => {
    sliderRef.current.slickGoTo(index, false);
  }

  const nextSlide = () => {
    sliderRef.current.slickNext();
  }

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  }

  const formatDate = (date) => {
    return `${date?.getMonth() + 1}/${date?.getDate()}/${date?.getFullYear()}`
  }

  useEffect(() => {
    if (projectDetails?.type === types.DOCUMENT) {
      sliderRef?.current?.slickGoTo(0);
    }
  }, [sliderRef?.current, projectDetails, types.DOCUMENT]);

  return (
    <>
      {projectDetails?.type === types.DOCUMENT && (
        <div className="project-details-modal">
          <div
            className="modal fade bg-dark-1 show"
            style={{display: "block"}}
            tabIndex={-1}
            aria-hidden="true"
            onClick={() => setIsOpen(false)}
          >
            <div
              className="modal-dialog modal-xl modal-fluid"
              onClick={(e) => e.stopPropagation()}
            >
              <div className={"modal-content rounded-0"}>
                <div className="modal-body">
                  <button
                    type="button"
                    className={"btn-close "}
                    onClick={() => setIsOpen(false)}
                  />
                  <div className={"container ajax-container "}>
                    <div className="row g-4">
                      <div className="col-md-12 col-sm-12">
                        <h2 className={"project-details-title text-center text-9 fw-600 mb-4"}>
                          {projectDetails?.title}
                        </h2>
                      </div>
                    </div>
                    <div className="p-3 mb-3">
                      <div className="row g-4">
                        <div className="col-md-8 col-sm-12">
                          <p className="mb-4 wow fadeInUp">
                            <span className="partial-underline text-dark text-5">Description</span>
                          </p>
                          <div dangerouslySetInnerHTML={{__html: projectDetails?.description}}></div>
                          <p className="mb-4 wow fadeInUp">
                            <span className="partial-underline text-dark text-5">Responsibilities</span>
                          </p>
                          <div dangerouslySetInnerHTML={{__html: projectDetails?.responsibilities}}></div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <BusinessIcon/>
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary="Client" secondary={projectDetails?.client}/>
                            </ListItem>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <DateRangeIcon/>
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary="Date"
                                            secondary={`${formatDate(projectDetails?.start)}-${formatDate(projectDetails?.end)}`}/>
                            </ListItem>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <WorkIcon/>
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary="Industry" secondary={projectDetails?.industry}/>
                            </ListItem>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <DataObjectIcon/>
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary="Technologies" secondary={
                                <div className="chip-list mt-1">
                                  {projectDetails?.technologies?.length > 0 &&
                                    projectDetails?.technologies?.map((item, index) => (
                                      <Chip label={item} size="small" className="mr-3"/>
                                    ))}
                                </div>
                              }/>
                            </ListItem>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <LabelImportantIcon/>
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary="Services" secondary={
                                <div className="chip-list mt-1">
                                  {projectDetails?.services?.length > 0 &&
                                    projectDetails?.services?.map((item, index) => (
                                      <Chip label={item} size="small" className="mr-3"/>
                                    ))}
                                </div>
                              }/>
                            </ListItem>
                          </List>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="row g-4">
                        <div className="col-md-12">
                          <p className="mb-2 wow fadeInUp">
                            <span className="partial-underline text-dark text-5">Screenshots</span>
                          </p>
                          <div style={{display: "block", overflow: 'hidden'}}>
                            <div style={{display: "flex", justifyContent: 'space-between'}} className={"mb-2"}>
                              <IconButton onClick={() => prevSlide()}>
                                <KeyboardArrowLeftIcon/>
                              </IconButton>
                              <div>
                                {projectDetails?.sliderImages.length > 0 &&
                                  projectDetails?.sliderImages.map((slide, index) => (
                                    <IconButton size="small"
                                                color={index == activeSliderIndex ? "primary" : "plain"}
                                                onClick={() => selectSlide(index)}>
                                      {index == activeSliderIndex ? <RadioButtonCheckedIcon/> :
                                        <RadioButtonUncheckedIcon/>}
                                    </IconButton>
                                  ))}
                              </div>
                              <IconButton onClick={() => nextSlide()}>
                                <KeyboardArrowRightIcon/>
                              </IconButton>
                            </div>
                            <Slider {...settings} ref={sliderRef}>
                              <div className="item">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src={projectDetails?.thumbImage}
                                />
                              </div>
                              {projectDetails?.sliderImages?.length > 0 &&
                                projectDetails?.sliderImages?.map(
                                  (image, index) => (
                                    <div className="item" key={index}>
                                      <img
                                        className="img-fluid"
                                        alt=""
                                        src={image}
                                      />
                                    </div>
                                  )
                                )}
                            </Slider>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {projectDetails?.type === types.IMAGE && (
        <div className="portfolio-image-modal" onClick={() => setIsOpen(false)}>
          <img
            src={projectDetails?.thumbImage}
            alt={projectDetails?.title}
            onClick={(e) => e.stopPropagation()}
          />
          <button
            type="button"
            className="btn-close btn-close-white opacity-10"
            onClick={() => setIsOpen(false)}
          ></button>
        </div>
      )}
      {projectDetails?.type === types.VIDEO && (
        <ModalVideo
          channel={projectDetails?.video?.vimeo ? "vimeo" : "youtube"}
          autoplay
          isOpen={projectDetails?.type === types.VIDEO}
          videoId={projectDetails?.video?.id}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default ProjectDetailsModal;

import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "Can you build a web application from scratch?",
      answer: `
      <p>
      Yes! In fact, I built many of the projects in my portfolio from scratch. Being a full-stack developer provides me with the skill set to handle all aspects of building a web application.
      </p>
      `,
    },
    {
      question: "Are you available for future additions and maintenance?	",
      answer: `
      <p>
      I always set aside some time each week for handling unforeseen work, which is typically maintenance and fixing bugs, so the answer is yes. However, I also offer guaranteed availability contracts, which I highly recommend if you need work completed in a short period of time.
      </p>
      <p>
      For future feature additions, this works the same way as building out a platform from scratch. As in, we’d need to evaluate the requirements and execute a contract.
      </p>
      `,
    },
    {
      question: "Do you work well with a team?",
      answer: `
      <p>
     Yes! Anyone that knows me, including clients, will agree that I’m a very outgoing and sociable person. I’ve worked on small and large teams throughout my career, as well as maintained various roles on those teams. I worked on teams at StockLinkU, TickerTocker, HomeGig, Webleash, and quite a few other projects.
      </p>
      `,
    },
    {
      question: "Are you willing to learn new technologies?",
      answer: `
      <p>
      I’m the nerd that buys textbooks to read on the beach, if that tells you anything. I truly love to learn new things, so please don’t hesitate to reach out even if you don’t see the tech you want to use listed on my website. It’s also worth mentioning that I’ve worked with a lot more technology than I’ve listed here.
      </p>
      `,
    },
    {
      question: "What's your biggest accomplishment?",
      answer: `
      <p>
      TODO
      </p>
      `,
    },
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-2">FAQ</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Frequently asked questions
            </h2>
            {/* Heading end*/}

            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/faq.png"
              title="FAQ"
              alt="faq"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;

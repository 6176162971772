import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import {CardHeader} from '@mui/material';
import resumeFile from '../documents/resume.pdf';

const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    STARTUPS: "Startup",
    ENTERPRISE: "Enterprise",
    FOUNDER: "Founder",

    FRONTEND: "Frontend",
    BACKEND: "Backend",
    DESIGN: "Design",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const addProject = (company, caption, description, industry, tags, start, end ) => {

  }

  const projectsData = [
    {
      title: "Algo & Social Trading Platform",
      client: "TickerTocker",
      industry: "Finance",
      caption: "A real-time trading web app that enabled following top traders including live video streams, live trading ideas, and automated copy trading. The platform also enabled designing and deploying algo trading strategies.",
      jobType: "Full-Time",
      label: "Senior Frontend Architect",
      start: new Date(2019, 4 - 1, 0),
      end: new Date(2020, 10 - 1, 0),
      description: `
      <p>
      TickerTocker was looking for an FE architect and engineer to contribute to getting their product to the finish line. My roles at TickerTocker included providing feedback for designs, designing the implementation of new features, implementing new functionality, fixing bugs, evaluating existing features, and working with other engineers (ex. code reviews).
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Collaborated with 4 teams to create designs and define requirements for new features.</li>
      <li>Architected and implemented complex streaming features including streaming charts, streaming performance analytics, and streaming trading tool utilized by 20,000 users.</li>
      <li>Re-built various legacy backend monolith services using microservices.</li>
      <li>Implemented custom technical indicators and algo trading strategies for top users.</li>
      <li>Implemented various pages utilizing provided Figma designs.</li>
      <li>Monitored, identified and fixed bugs.</li>
      <li>Won numerous Stevie awards related to the frontend work that I performed.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "PHP", "Javascript", "Typescript", "CSS", "HTML", "Spring Boot", "WebSockets", "RxJs", "Streaming", "ChartIQ", "Kafka", "Kubernetes", "REST API"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/tickertocker/performance_tracker.png",
        "images/projects/screenshots/tickertocker/chart_trade_ideas.png",
        "images/projects/screenshots/tickertocker/ladder_oco.png",
        "images/projects/screenshots/tickertocker/ladder_trg.png",
        "images/projects/screenshots/tickertocker/order_book.png",
        "images/projects/screenshots/tickertocker/screener.png",
        "images/projects/screenshots/tickertocker/verified_trades.png",
        "images/projects/screenshots/tickertocker/verified_trades2.png",
      ],
      thumbImage: "images/projects/screenshots/tickertocker/ladder_oco.png",

      categories: [filters.STARTUPS, filters.FRONTEND, filters.DESIGN],
    },
    {
      title: "3D Masonry Virtual Showroom",
      client: "Spec4Direct",
      industry: "Construction Materials",
      label: "Senior Full-Stack Engineer",
      caption: "This is the caption. A quick description of the project.",
      jobType: "Contract",
      start: new Date(2019, 2 - 1, 0),
      end: new Date(2019, 12 - 1, 0),
      description: `
      <p>
      The client, Spec4Direct, was founded by a successful entrepreneur that envisioned a better way of distributing masonry products to contractors while also streamlining the distribution process. They wanted to build a “virtual showroom” that enabled their sales reps and associates to be able to showcase their masonry products utilizing interactive 3D displays.
      </p>
      `,
      responsibilities: `
       <ul>
       <li>Designed and developed a web app to enable a masonry distribution company to showcase their products in 3D.</li>
       <li>Developed the ability for sales reps to build out proposals, including generating PDF and interactive versions of each proposal.</li>
      <li>Designed a process for taking masonry manufacturers' products and converting them into 3D objects, while abiding by recent BIM standards.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB", "AngularJS", "ThreeJS", "WebGL", "REST API"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/spec4direct/vendor.png",
        "images/projects/screenshots/spec4direct/unit.png",
        "images/projects/screenshots/spec4direct/vendor.png",
        "images/projects/screenshots/spec4direct/manage_project.png",
        "images/projects/screenshots/spec4direct/generate_files.png",
        "images/projects/screenshots/spec4direct/create_products.png",
        "images/projects/screenshots/spec4direct/create_vendor.png",
        "images/projects/screenshots/spec4direct/specify_product_data.png",
        "images/projects/screenshots/spec4direct/all_products.png",
      ],
      thumbImage: "images/projects/screenshots/spec4direct/3d.png",

      categories: [filters.ENTERPRISE, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },
    {
      title: "Real-Time Trading Simulator",
      client: "StockLinkU",
      industry: "Finance",
      label: "Founder & Full-Stack Engineer",
      caption: "A financial education platform for giving students hands-on experience with the concepts being taught in class with the same tools as practitioners use. It was primarily focused on investment management and financial planning.",
      jobType: "Full-Time",
      start: new Date(2010, 2 - 1, 0),
      end: new Date(2015, 12 - 1, 0),
      description: `
      <p>
      I started StockLinkU in my senior year of college. During the five years of working on StockLinkU, I learned an enormous amount about every aspect of building and running a SaaS company. The idea was to build a platform that enabled students in markets-related courses, especially investment management, to be able to apply the concepts they were learning in class using the same tools as practitioners. The goal was to provide students with an interactive and immersive hands-on experience, while also better preparing them for the workplace. 
      </p>
            <p>
      The platform enabled students to trade all major security types  (equities, mutual funds, options, futures, spots, bonds, forex) on 25+ global exchanges in real-time. The platform was also equipped with a comprehensive portfolio management system with news, research, allocation breakdowns, allocation risk/return contribution/attribution, portfolio performance analytics, portfolio vs benchmark performance analytics, a large suite of reports, and a collection of sophisticated tools (optimization, simulation, scenario analysis, backtesting, etc.). 
      </p>
            <p>
      Furthermore, instructors had the ability to build their own virtual brokerage for students to trade from, customize students' experiences, and track every aspect of their students’ portfolios, including a custom ranking mechanism and a large suite of class-wide reports.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Led and managed a distributed team of 10 technical team members from conception to completion.</li>
      <li>Designed and developed a real-time simulated trading engine that traded all major security types on 25+ exchanges.</li>
      <li>Designed and developed a large suite of complex portfolio management tools, analytics, and reports.</li>
      <li>Synchronized data from 8 data providers for over 2 million securities.</li>
      <li>Scaled to 160 schools, 20,000+ students, and offered a contract by McGraw-Hill to be included in 120,000 textbooks.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "CSS", "HTML", "JQuery", "Bootstrap", "KnockoutJS", "PostgreSQL", "Highcharts", "Integrations"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/stocklinku/student_dashboard.png",
        "images/projects/screenshots/stocklinku/student_excelreport.png",
        "images/projects/screenshots/stocklinku/student_pdfreport.png",
        "images/projects/screenshots/stocklinku/student_positions.png",
        "images/projects/screenshots/stocklinku/student_tradingjournal.png",
        "images/projects/screenshots/stocklinku/project_main.png",
        "images/projects/screenshots/stocklinku/instructor_project.png",
        "images/projects/screenshots/stocklinku/project_commissions.png",
        "images/projects/screenshots/stocklinku/instructor_excelreport.png",
        "images/projects/screenshots/stocklinku/instructor_reports.png",
      ],
      thumbImage: "images/projects/thumbnails/stocklinku.png",

      categories: [filters.STARTUPS, filters.FOUNDER, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },
    {
      title: "Frontend Solutions for Safety Devices",
      client: "Katana Safety",
      industry: "Retail Devices",
      label: "Senior Full-Stack Engineer",
      caption: "This is the caption. A quick description of the project.",
      jobType: "Contract",
      start: new Date(2020, 5 - 1, 0),
      end: new Date(2021, 4 - 1, 0),
      description: `
      <p>
      The client, Katana Safety, built a device that attached to the back of mobile phones that provided various safety features to its owner. The company had already received a lot of attention from large media outlets, including being included in Oprah’s Favorite Things. They had a few non-product-related technology initiatives and were looking for an engineer to help them with their initiatives. 
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Designed and developed a web-based brand ambassador portal to enable brand ambassadors to sign-up, access program information, browse available content, manage/track their earnings, and communicate with their designated manager.</li>
      <li>Designed and developed a custom interactive shopping cart and product customization widget that integrated with various payment processors and the company’s inventory management system (WooCommerce). </li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/katanasafety/ambassador/ambassador_dashboard_2.png",
        "images/projects/screenshots/katanasafety/ambassador/admin.png",
        "images/projects/screenshots/katanasafety/ambassador/ambassador_templates_1.png",
        "images/projects/screenshots/katanasafety/ambassador/ambassador_templates_2.png",
        "images/projects/screenshots/katanasafety/ambassador/public.png",
        "images/projects/screenshots/katanasafety/ambassador/signup.png",
        "images/projects/screenshots/katanasafety/cart/cart.png",
        "images/projects/screenshots/katanasafety/cart/customize.png",
        "images/projects/screenshots/katanasafety/cart/payment.png",
        "images/projects/screenshots/katanasafety/cart/shipping.png",
      ],
      thumbImage: "images/projects/screenshots/katanasafety/ambassador/ambassador_dashboard_1.png",

      categories: [filters.STARTUPS, filters.FRONTEND, filters.DESIGN],
    },
    {
      title: "3D PV Design Platform",
      client: "DaylightTools",
      industry: "Renewable Energy",
      label: "Senior Full-Stack Engineer",
      caption: "A solar company looking for its own proprietary platform for building proposals in 3D.",
      jobType: "Contract",
      start: new Date(2021, 6 - 1, 0),
      end: new Date(2023, 5 - 1, 0),
      description: `
      <p>
      The client, Wolf River Electric, is a solar installation company that saw a need for a more interactive platform for solar sales. 
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Designed and developed a SaaS platform for generating proposals for photovoltaic (PV) systems.</li>
      <li>Developed an interactive 3D PV system designer using mapping libraries, WebGL, & geospatial data.</li>
      <li>Documented and developed simulation & forecasting capabilities for rates, loans, and energy.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB", "ReactJS", "ChartJS", "ThreeJS", "WebGL", "Serverless", "REST API"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/daylighttools/dashboard.png",
        "images/projects/screenshots/daylighttools/design_studio.png",
        "images/projects/screenshots/daylighttools/losses.png",
        "images/projects/screenshots/daylighttools/notifications.png",
        "images/projects/screenshots/daylighttools/production.png",
        "images/projects/screenshots/daylighttools/proposal.png",
        "images/projects/screenshots/daylighttools/settings.png",
        "images/projects/screenshots/daylighttools/utility_rates.png",
      ],
      thumbImage: "images/projects/screenshots/daylighttools/presentation.png",

      categories: [filters.STARTUPS, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },
    {
      title: "Mortgage Company Website",
      client: "LowCountry Home Loans",
      industry: "Mortgage Loans",
      label: "Senior Full-Stack Engineer",
      caption: "A mortgage company that was looking to create a modern website that represented their brand.",
      jobType: "Contract",
      start: new Date(2021, 10 - 1, 0),
      end: new Date(2022, 3 - 1, 0),
      description: `
      <p>
      The client, Lowcountry Home Loans, was looking to create a modern website that showcased their various services that compared to a few of the big players in the industry. They were also wanting a lead generation tool embedded on their homepage. 
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Designed and developed a single page website to showcase the company and it’s services.</li>
      <li>Developed a conditional wizard embedded on their homepage for quick and easy lead generation.</li>
      <li>Designed a new logo and various infographics for the website.</li>
      <li>Integrated and designed a loan calculator into the website.</li>
      <li>Integrated an aggregated reviews feed into the website.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/lowcountryloans/calculator.png",
        "images/projects/screenshots/lowcountryloans/infographic.png",
        "images/projects/screenshots/lowcountryloans/programs.png",
        "images/projects/screenshots/lowcountryloans/video.png",
        "images/projects/screenshots/lowcountryloans/wizard.png",
        "images/projects/screenshots/lowcountryloans/chat.png",
      ],
      thumbImage: "images/projects/screenshots/lowcountryloans/home.png",

      categories: [filters.ENTERPRISE, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },
    {
      title: "PLC Programming",
      client: "SwedgeLock Aluminum",
      industry: "Manufacturing",
      label: "Senior Full-Stack Engineer",
      caption: "An aluminum fence and railing manufacturing company with an innovative machine for automating the assembly of their 180 different product variations.",
      jobType: "Full-Time",
      start: new Date(2018, 1 - 1, 0),
      end: new Date(2020, 6 - 1, 0),
      description: `
      <p>
      The client, SwedgeLock Aluminum, is an aluminum fence and railing manufacturing company with an innovative machine for automating the assembly of their 180 different product variations.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Extended an existing PLC program to accommodate an additional 50 designs.</li>
      <li>Adjusted an existing PLC program to work for a completely different machine configuration.</li>
      <li>Designed and implemented a custom HMI program for machine operators.</li>
      <li>Optimized machine configurations to minimize production time.</li>
      <li>Debugged various production issues.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/swedgelok/brochures/businesscard_front.png",
        "images/projects/screenshots/swedgelok/brochures/swedgelock_fencing_brochure-1.png",
        "images/projects/screenshots/swedgelok/brochures/swedgelock_fencing_brochure-2.png",
        "images/projects/screenshots/swedgelok/brochures/swedgelock_machine_brochure-1.png",
        "images/projects/screenshots/swedgelok/brochures/swedgelock_railing_brochure-1.png",
        "images/projects/screenshots/swedgelok/brochures/swedgelock_railing_brochure-2.png",
      ],
      thumbImage: "images/projects/screenshots/swedgelok/brochures/businesscard_front.png",

      categories: [filters.ENTERPRISE, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },
    {
      title: "Financial Planning Tools",
      client: "Founder",
      industry: "Finance",
      label: "Full-Stack Engineer",
      caption: "A suite of financial planning tools I built to add to my company's fintech platform.",
      jobType: null,
      start: new Date(2016, 7 - 1, 0),
      end: new Date(2018, 1 - 1, 0),
      description: `
      <p>
      A suite of financial planning tools I built to add to my company's fintech platform. The goal was to extend our existing portfolio management capabilities to also include financial planning.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Designed and developed the ability to collect data from clients virtually (i.e. prior to in-person meeting).</li>
      <li>Developed the ability to automatically sync or manually create financial profiles for clients.</li>
      <li>Developed the ability to build and analyze goal-based financial plans.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/financialplanning/assumptions.png",
        "images/projects/screenshots/financialplanning/DataTablsMultipurpose.png",
        "images/projects/screenshots/financialplanning/evaluation.png",
        "images/projects/screenshots/financialplanning/financials.png",
        "images/projects/screenshots/financialplanning/goals.png",
        "images/projects/screenshots/financialplanning/goals_chart.png",
        "images/projects/screenshots/financialplanning/models.png",
        "images/projects/screenshots/financialplanning/profile.png",
        "images/projects/screenshots/financialplanning/proposal_1.png",
        "images/projects/screenshots/financialplanning/proposal_2.png",
        "images/projects/screenshots/financialplanning/proposal_3.png",
        "images/projects/screenshots/financialplanning/wizard.png"
      ],
      thumbImage: "images/projects/screenshots/financialplanning/financials_adjusted.png",

      categories: [filters.STARTUPS, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },

    {
      title: "Lending Platform for Contractors",
      client: "BuilderCommerce - BuildFi",
      industry: "Construction",
      label: "Lead Frontend Software Engineer",
      caption: "This is the caption. A quick description of the project.",
      jobType: "Contract",
      start: new Date(2019, 2 - 1, 0),
      end: new Date(2020, 10 - 1, 0),
      description: `
      <p>
      The client, BuildFi (a subsidiary of BuilderCommerce), was an early-stage funded MPL (Marketplace Lending Platforms) with a 30+ team that was focused on serving the construction industry. Their initial focus was on contractors/entities in the “house flipping” industry. They had already built a solid infrastructure on top of Microsoft Dynamic’s 365 for lenders, but needed a consumer-facing frontend, which is where I came in. 
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Performed market research to evaluate existing and similar platforms.</li>
      <li>Collaborated with management, investors, professionals, and engineers on the design.</li>
      <li>Created the front-end theme and designs for all customer-facing page using Figma.</li>
      <li>Implemented designs utilizing AngularJS and built a skeleton REST API integration.</li>
      <li>Collaborated with principals via JIRA on iterations until completion.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/buildercommerce/blog.png",
        "images/projects/screenshots/buildercommerce/lender_dashboard.png",
        "images/projects/screenshots/buildercommerce/borrowers_dashboard.png",
        "images/projects/screenshots/buildercommerce/loan.png",
        "images/projects/screenshots/buildercommerce/loan_2.png",
        "images/projects/screenshots/buildercommerce/wizard.png",
        "images/projects/screenshots/buildercommerce/email_2.png",
        "images/projects/screenshots/buildercommerce/contact.png",
      ],
      thumbImage: "images/projects/screenshots/buildercommerce/lender_dashboard.png",

      categories: [filters.STARTUPS, filters.FRONTEND, filters.DESIGN],
    },
    {
      title: "Dynamic Talent Assessments",
      client: "Personal - Meistera",
      industry: "Recruiting",
      label: "Founder & Full-Stack Engineer",
      caption: "A web app for enabling employers to build job talent assessments utilizing real/dynamic data and extract measurables to identify candidates that perform similarly to top-performing employees.",
      jobType: null,
      start: new Date(2015, 1 - 1, 0),
      end: new Date(2016, 7 - 1, 0),
      description: `
      <p>
      This was a personal project that I worked on alongside of Charles Handler, a renowned psychologist and talent assessment specialist. The idea was to build a platform that enabled employers to build talent assessments for hiring that integrated industry and/or company data sources directly into their assessments used for hiring. Furthermore, provide employers with the ability to extract key measurables that align with their existing top performers.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Designed and developed a mobile-compatible web app that enabled users to sign-up and utilize their platform.</li>
      <li>Developed the ability for employers to build talent assessments utilizing relevant internal and external data sources (ex., databases and spreadsheets)  to build realistic assessments.</li>
      <li>Developed the ability to incorporate and measure key attributes from each assessment to identify candidates that align with top performers.</li>
      <li>Developed a simple applicant tracking system to be able to manage, including sending invitations, and evaluate candidates.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/meister/browse_assessments.png",
        "images/projects/screenshots/meister/admin_usermanager.png",
        "images/projects/screenshots/meister/dashboard.png",
        "images/projects/screenshots/meister/data.png",
        "images/projects/screenshots/meister/edit_question.png",
        "images/projects/screenshots/meister/job_profile.png",
        "images/projects/screenshots/meister/user_dashboard.png",
        "images/projects/screenshots/meister/admin_createAssessment.png",
        "images/projects/screenshots/meister/admin_createAssessmentAssignment.png",
        "images/projects/screenshots/meister/admin_createEmployee.png",
        "images/projects/screenshots/meister/admin_createInstitution.png",
        "images/projects/screenshots/meister/admin_createJob.png",
        "images/projects/screenshots/meister/admin_createJobProfile.png",
        "images/projects/screenshots/meister/admin_createProject.png",
        "images/projects/screenshots/meister/admin_createProjectAssignment.png",
        "images/projects/screenshots/meister/admin_dashboard.png",
        "images/projects/screenshots/meister/admin_databrowser.png",

      ],
      thumbImage: "images/projects/thumbnails/meister.png",

      categories: [filters.STARTUPS, filters.FOUNDER, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },
    {
      title: "Financial Planning App",
      client: "Clariata",
      industry: "Finance",
      label: "Senior Frontend Engineer",
      caption: "This is the caption. A quick description of the project.",
      jobType: "Contract",
      start: new Date(2020, 3 - 1, 0),
      end: new Date(2021, 2 - 1, 0),
      description: `
      <p>
      The client, Clariata, is a financial planning company that offers a unique planning process designed to deepen the advisor/client’s personal relationship and trust as they collectively develop a strategic plan for a life well lived. They were seeking an engineer to build out an interactive frontend to replace their existing manual process and help clients visualize the value of their unique approach.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Collaborated with backend developer to design and build a REST API to power frontend.</li>
      <li>Developed a complex conditional form wizard to facilitate a unique financial planning process.</li>
      <li>Developed real-time data visualizations embedded in the form wizard.</li>
      <li>Saved the client at least two full days from having to perform the process manually.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["ASP.NET", "C#", "Javascript", "Typescript", "CSS", "HTML", "ReactJS", "MariaDB", "REST API"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/clariata/1.png",
        "images/projects/screenshots/clariata/2.png",
        "images/projects/screenshots/clariata/3.png",
        "images/projects/screenshots/clariata/4.png",
        "images/projects/screenshots/clariata/4.png",
        "images/projects/screenshots/clariata/5.png",
        "images/projects/screenshots/clariata/7.png",
        "images/projects/screenshots/clariata/8.png",
        "images/projects/screenshots/clariata/9.png",
        "images/projects/screenshots/clariata/10.png",
        "images/projects/screenshots/clariata/11.png",
        "images/projects/screenshots/clariata/12.png",
      ],
      thumbImage: "images/projects/screenshots/clariata/7.png",

      categories: [filters.ENTERPRISE, filters.FRONTEND, filters.DESIGN],
    },

    {
      title: "Website & CRM",
      client: "AAA Fencecrafters",
      industry: "Construction",
      label: "Senior Full-Stack Engineer",
      caption: "Developed a modern website for a custom fence contractor, as well as a comprehensive custom CRM.",
      jobType: "Full-Time",
      start: new Date(2018, 1 - 1, 0),
      end: new Date(2020, 7 - 1, 0),
      description: `
      <p>
      The client, AAA Fencecrafters, is a fence contractor known for being able to handle anything you can throw at them, so they wanted their website to reflect this. Furthermore, they were encountering bottlenecks in various aspects of their business due to rapid growth and wanted a CRM to help them address these bottlenecks.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Developed a comprehensive CRM tailored to the fence industry.</li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/kontractor/app/dashboard.png",
        "images/projects/screenshots/kontractor/app/communication_log.png",
        "images/projects/screenshots/kontractor/app/jobs.png",
        "images/projects/screenshots/kontractor/app/opportunities.png",
        "images/projects/screenshots/kontractor/app/estimates.png",
        "images/projects/screenshots/kontractor/app/estimate_itemized.png",
        "images/projects/screenshots/kontractor/app/estimate_drawing.png",
        "images/projects/screenshots/kontractor/app/product.png",
        "images/projects/screenshots/kontractor/app/product_profile.png",
        "images/projects/screenshots/kontractor/app/style_config.png",
        "images/projects/screenshots/kontractor/app/style_materials.png",
        "images/projects/screenshots/aaafencecrafters/website/services.png",
      ],
      thumbImage: "images/projects/screenshots/kontractor/app/dashboard.png",

      categories: [filters.ENTERPRISE, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },

    {
      title: "Portfolio Tracking & Planning",
      client: "Founder - FolioInsight",
      industry: "Finance",
      label: "Founder & Senior Full-Stack Engineer",
      caption: "A web app that provides self directed investors the same tools as institutional investors.",
      jobType: null,
      start: new Date(2020, 2 - 1, 0),
      end: new Date(2022, 12 - 1, 0),
      description: `
      <p>
      FolioInsight was an extension of the educational portfolio management platform that I built for students, StockLinkU, to enable self-directed investors to utilize the same tools as institutional investors. In order to do so, I built integrations with account aggregation platforms to enable investors to sync their investment accounts into the system. 
      </p>
      `,
      responsibilities: `

      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/folioinsight/plan_plan.png",
        "images/projects/screenshots/folioinsight/plan_profile.png",
        "images/projects/screenshots/folioinsight/models.png",
        "images/projects/screenshots/folioinsight/model_new.png",
        "images/projects/screenshots/folioinsight/trade.png",
        "images/projects/screenshots/folioinsight/new_portfolio.png",
        "images/projects/screenshots/folioinsight/new_tracking_portfolio.png",
        "images/projects/screenshots/folioinsight/positions.png",
        "images/projects/screenshots/folioinsight/reports.png",
        "images/projects/screenshots/folioinsight/alert.png",
      ],
      thumbImage: "images/projects/screenshots/folioinsight/plan_plan.png",

      categories: [filters.STARTUPS, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },
    {
      title: "Custom Tools",
      client: "Personal",
      label: "Senior Full-Stack Engineer",
      caption: "A few standardized tools that I've built.",
      jobType: null,
      start: new Date(2016, 1 - 1, 0),
      end: new Date(2016, 2 - 1, 0),
      description: `
      <p>
      I was constantly running into a very similar set of automation requirements on projects, so I decided to spend the time to design  and build out what I considered that ideal generic solutions at the time.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Designed and developed a workspace builder. Workspaces are simply pages comprised of configurable widgets. </li>
      <li>Created a standardized framework for building workspaces, including data models and building API endpoints to power widgets. </li>
      <li>Designed and developed a form and form wizard builder. </li>
      <li>Created a standardized framework for building forms and form wizards, including data models and building API endpoints to power them. </li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Javascript", "Typescript", "CSS", "HTML", "AngularJS"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/reportbuilder/workspace_builder.png",
      ],
      thumbImage: "images/projects/screenshots/reportbuilder/builder_form.png",

      categories: [filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },
    {
      title: "Website & Estimating Tool",
      client: "Carolina Staining",
      industry: "Construction",
      label: "Senior Full-Stack Engineer",
      caption: "A website for my first company that included a lead pre-qualifying tool utilizing Google Maps.",
      jobType: null,
      start: new Date(2013, 1 - 1, 0),
      end: new Date(2016, 9 - 1, 0),
      description: `
      <p>
      Carolina Staining was a company that I started in my freshman year in highschool. It became extremely time consuming to have to provide quotes in person and also accommodate school among other activities, especially when I’d only end up winning 50-60% of the projects that I bid on. That being said, I decided to build a pre-qualification tool for my website that would enable potential customers to measure their yard using Google Maps, choose the services they needed, and receive a general quote for the services needed. 
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Designed and developed a single page website to showcase the company and it’s services.</li>
      <li>Developed a map-based widget that enabled potential customers to measure their backyard, choose the services they need, and receive an estimated quote.</li>
      <li>Developed an admin backend for customzing service pricing and utilizing the same quoting tool to generate proposals.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "HTML", "CSS", "Google Maps"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/carolinastaining/home.png",
        "images/projects/screenshots/carolinastaining/estimator_map.png",
        "images/projects/screenshots/carolinastaining/estimator_output.png",
        "images/projects/screenshots/carolinastaining/logo.png",
      ],
      thumbImage: "images/projects/screenshots/carolinastaining/home.png",

      categories: [filters.FOUNDER, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },
    {
      title: "Personal Integration Portal",
      client: "Webleash",
      industry: "Apps",
      label: "Senior Full-Stack Engineer",
      caption: "A web app for enabling users to view everything they want to see, how they want it. ",
      jobType: "Contract",
      start: new Date(2018, 7 - 1, 0),
      end: new Date(2019, 12 - 1, 0),
      description: `
      <p>
      The client was a successful attorney that had an idea for an app that they primarily wanted to utilize themselves, but also wanted me to consider commercialization at some later point. I was designated as the lead engineer for an existing team of two engineers. The concept was essentially to build a personalized and customizable set of dashboards that would sync data from various sources to power the widgets nested in each dashboard. The client wanted to focus on emails, calendars, social media, weather, news, and tasks for the initial version.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Designed and developed a mobile compatible web app that enabled users to sign-up and utilize their platform.</li>
      <li>Developed universal models for emails, calendars, social media, news, and tasks to enable syncing and mapping data from various sources to single unified models.</li>
      <li>Developed integrations with 10+ services, as well as the ETL required to map ingested data to the unified models.</li>
      <li>Developed drag-and-drop widgets to enable users to build out their dashboard and specify how they wanted data to be sorted and filtered within each widget.</li>
      <li>Developed the ability for users to customize their incoming news feed utilizing categories, entities, and other common natural language processing attributes.</li>
      <li>Developed a mechanism for tracking the categories and entities of viewed articles for each user to tailor future highlighted articles.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/webleash/login.png",
      ],
      thumbImage: "images/projects/screenshots/webleash/login.png",

      categories: [filters.STARTUPS, filters.FRONTEND, filters.BACKEND],
    },
    {
      title: "Home Services Platform",
      client: "HomeGig",
      industry: "Home Services",
      label: "Senior Frontend Architect",
      caption: "An innovative platform that enables homeowners to get quotes and facilitate their home projects virtually.",
      jobType: "Contract",
      start: new Date(2020, 11 - 1, 0),
      end: new Date(2023, 6 - 1, 0),
      description: `
      <p>
      The client, a successful tech entrepreneur, reached out to me regarding an idea that he had in the home services industry. The idea was essentially to build a platform to enable homeowners to post home projects, contractors to bid on the projects, homeowners to select a contractor to move forward with, and then facilitate the many administrative tasks involved in managing home services projects (ex. communication and payments). My role was a Senior Front-end Architect, but I ended up taking on a lot of additional responsibilities.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Converted figma designs into an Angular frontend for the mass majority of the app.</li>
      <li>Developed and maintained the primary Angular component library.</li>
      <li>Developed a lead generation server less function that utilized various APIs to find and suggest contractors, registered and non-registered, based on certain input parameters. It also aggregated key contact details for people within each company.</li>
      <li>Created a standardized framework for designing complex for wizards for each service offered. Also created the initial wizard definitions for the top 15 services.</li>
      <li>Integrated and configured Freshdesk, as well as related services, into the platform. Included populating all initial help content and designing the portal to match brand standards.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "Angular", "PostgreSQL", "Figma", "AppScript", "WebGL", "REST API"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/homegig/project.png",
        "images/projects/screenshots/homegig/login.png",
        "images/projects/screenshots/homegig/post_project.png",
        "images/projects/screenshots/homegig/project.png",
        "images/projects/screenshots/homegig/project_designer.png",
        "images/projects/screenshots/homegig/projects.png",
        "images/projects/screenshots/homegig/website_home.png",
        "images/projects/screenshots/homegig/website_home2.png",
        "images/projects/screenshots/homegig/website_home3.png",
        "images/projects/screenshots/homegig/website_howitworks.png",
        "images/projects/screenshots/homegig/website_howitworks2.png",
        "images/projects/screenshots/homegig/welcome.png",
        "images/projects/screenshots/homegig/email_template.png",
      ],
      thumbImage: "images/projects/screenshots/homegig/project.png",

      categories: [filters.STARTUPS, filters.FRONTEND, filters.BACKEND],
    },
    {
      title: "Wealth Management Custom Solutions",
      client: "Optimize Wealth",
      industry: "Finance",
      label: "Senior Full-Stack Engineer",
      caption: "A quickly growing wealth management company utilizing technology to optimize every aspect of its business.",
      jobType: null,
      start: new Date(2021, 11 - 1, 0),
      end: new Date(2023, 4 - 1, 0),
      description: `
      <p>
      
      </p>
      `,
      responsibilities: `
       <ul>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Javascript", "Typescript", "VueJS", "Quasar", "AmCharts", "Hubspot", "Serverless", "AWS Lambda", "REST API"],
      types: ["Web App", "Website"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/optimize/dcr.png",
        "images/projects/screenshots/optimize/dcr1.png",
        "images/projects/screenshots/optimize/dcr2.png",
        "images/projects/screenshots/optimize/dcr3.png",
        "images/projects/screenshots/optimize/dcr4.png",
        "images/projects/screenshots/optimize/portfolio1.png",
        "images/projects/screenshots/optimize/portfolio2.png",
        "images/projects/screenshots/optimize/portfolio3.png",
        "images/projects/screenshots/optimize/portfolio4.png",
        "images/projects/screenshots/optimize/portfolio5.png",
        "images/projects/screenshots/optimize/portfolios.png",
      ],
      thumbImage: "images/projects/screenshots/optimize/portfolio2.png",

      categories: [filters.ENTERPRISE, filters.FRONTEND, filters.BACKEND],
    },
    {
      title: "Real Estate Collaboration Platform",
      client: "OfferIn",
      industry: "Real-Estate",
      label: "Senior Full-Stack Engineer",
      caption: "A web app for enabling parties involved in real-estate transactions to collaborate, as well as collect and complete required documents.",
      jobType: "Full-Time",
      start: new Date(2018, 1 - 1, 0),
      end: new Date(2020, 6 - 1, 0),
      description: `
      <p>
      The client was a small group of young entrepreneurs that was focused on allocating small amounts of capital to test ideas generated from their group. This particular idea was building a centralized platform to enable residential real-estate negotiations and transactions to happen virtually (i.e. utilizing virtual communications via the web and mobile app instead of using calls and emails). I was asked to build a minimal prototype based on this idea.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Designed and developed a mobile-compatible web app that enabled users to sign-up and utilize their platform.</li>
      <li>Collaborated with the principals on the project to define and refine the requirements.</li>
      <li>Developed a workflow engine to automate the dynamic non-linear processes involved.</li>
      <li>Developed the ability for parties to collaborate in real time and invite other relevant parties to joining the conversation.</li>
      <li>Developed the ability for homebuyers and their agents to make virtual offers on properties that would prompt the seller’s parties to sign-up and make negotiations via the platform’s communication mediums.</li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/offerin/dashboard.png",
        "images/projects/screenshots/offerin/login.png",
        "images/projects/screenshots/offerin/offer_status.png",
        "images/projects/screenshots/offerin/offer_status2.png",
        "images/projects/screenshots/offerin/offer_status3.png",
        "images/projects/screenshots/offerin/register.png",
        "images/projects/screenshots/offerin/wizard.png",
        "images/projects/screenshots/offerin/wizard2.png",
      ],
      thumbImage: "images/projects/screenshots/offerin/wizard2.png",

      categories: [filters.STARTUPS, filters.FRONTEND, filters.BACKEND],
    },


    {
      title: "Roofing Estimation App",
      client: "Restimate",
      industry: "Construction",
      label: "Senior Full-Stack Engineer",
      caption: "A web app for enabling complex roof proposals.",
      jobType: "Contract",
      start: new Date(2021, 6 - 1, 0),
      end: new Date(2022, 1 - 1, 0),
      description: `
      <p>
      The client owned a rapidly growing roofing company with a manual excel-based quoting quoting process that they wanted to automate as much as possible. I was provided a general list of requirements and the complex spreadsheet that they were currently using for quoting. They wanted the ability to be able to customize the vendors, products, and calculations directly from the app. They also wanted me to consider that they would likely commercialize the app at some point in the future.
      </p>
      `,
      responsibilities: `
       <ul>
      <li>Designed and developed a mobile compatible web app that enabled companies to sign-up and utilize their platform.</li>
      <li>Developed an admin portal that enabled admins to customize the vendors, products, and formulas utlized for generating and calculating proposals.</li>
      <li>Developed a quoting wizard that enabled sales reps to model complex projects and collected all other necessary details for proposal generation.</li>
      <li>Developed an interactive customer-facing proposal that enabled sales reps to show customers the price variations for various product selections.</li>
      <li>Designed and developed a PDF version of the proposal. </li>
      </ul>
      `,
      type: types.DOCUMENT,
      technologies: ["Java", "Javascript", "Typescript", "CSS", "HTML", "MongoDB"],
      types: ["Web App"],
      services: ["Frontend", "Backend", "Design"],
      sliderImages: [
        "images/projects/screenshots/restimate/estimate.png",
        "images/projects/screenshots/restimate/estimate_calculations.png",
        "images/projects/screenshots/restimate/estimate_calculations_testing.png",
        "images/projects/screenshots/restimate/estimate_calculations2.png",
        "images/projects/screenshots/restimate/estimate_calculations3.png",
        "images/projects/screenshots/restimate/estimate2.png",
        "images/projects/screenshots/restimate/estimate3.png",
        "images/projects/screenshots/restimate/estimate4.png",
        "images/projects/screenshots/restimate/estimate5.png",
        "images/projects/screenshots/restimate/estimate6.png",
        "images/projects/screenshots/restimate/estimate7.png",
        "images/projects/screenshots/restimate/estimates.png",
        "images/projects/screenshots/restimate/product.png",
        "images/projects/screenshots/restimate/products.png",
        "images/projects/screenshots/restimate/services.png",
        "images/projects/screenshots/restimate/style.png",
        "images/projects/screenshots/restimate/styles.png",
        "images/projects/screenshots/restimate/style_edit.png",
        "images/projects/screenshots/restimate/vendors.png",
      ],
      thumbImage: "images/projects/screenshots/restimate/estimate5.png",

      categories: [filters.STARTUPS, filters.FRONTEND, filters.BACKEND, filters.DESIGN],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            My work
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <Card className="portfolio-box p-0">
                      <CardHeader title={
                        <Box sx={{ fontSize: '0.7em' }}>
                          {project?.title}
                        </Box>
                      }></CardHeader>
                      <CardContent className="p-0">
                        <div className="portfolio-img">
                          <img
                            onLoad={() => {
                              setimagesLoaded(imagesLoaded + 1);
                            }}
                            className="img-fluid d-block portfolio-image"
                            src={project.thumbImage}
                            alt=""
                          />
                          <div
                            className="portfolio-overlay"
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                              setIsOpen(true);
                            }}>
                            <button className="popup-ajax stretched-link border-0 p-0 ">
                              {" "}
                            </button>
                            <div className="portfolio-overlay-details">
                              <div className="header">
                                {project?.title}
                              </div>
                              <div className="body">
                                {project?.caption}
                              </div>
                            </div>
                          </div>
                          *{/*
                          <div className="portfolio-caption">
                            <button className="popup-ajax stretched-link border-0 p-0 ">
                              {" "}
                            </button>
                            <div className="portfolio-caption-details">
                            <span className="text-white text-5">
                              {project?.title}
                            </span>
                               <span className="text-light">{project?.title}</span>
                            </div>
                          </div>
                          */}*
                        </div>

                      </CardContent>
                    </Card>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
